import React from 'react'

const NotFoundPage = () => (
  <div className="container">
    <div className="containerGroup">
      <h2>listen: don't lost in void!<br/>… return void;</h2>
      <h2>(translate: page not found)</h2>
    </div>
  </div>
)

export default NotFoundPage
